<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
          <ion-back-button color="primary"></ion-back-button>
        </ion-buttons>
        <ion-title>Nuovo Cost</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
        </ion-toolbar>
      </ion-header>   
      <div id="container">
        <form @submit="submit">
          <label>Cost:</label>
          <ion-input id="input" v-model="cost.cost"></ion-input>
          <label>Descrizione:</label>
          <ion-input id="input" v-model="cost.description"></ion-input>
          <ion-button type="submit">SALVA</ion-button>
        </form>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
  import { 
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent, 
    IonHeader,
    IonInput, 
    IonMenuButton, 
    IonPage, 
    IonTitle, 
    IonToolbar,
    toastController,
  } from '@ionic/vue';

  export default {
    name: 'Folder',
    components: {
      IonBackButton,
      IonButton,
      IonButtons,
      IonContent,
      IonHeader,
      IonInput, 
      IonMenuButton,
      IonPage,
      IonTitle,
      IonToolbar
    },
   data: function(){
      return{
        cost:{
          cost:'',
          description:'',
        }
      }
    },
    methods:{
      submit(ev){
        ev.preventDefault();
        this.axios.post(this.apiUrl + 'save-cost', {cost:this.cost}, this.headers)
        .then(async (response) => {
          if(response.data.status_code==200){
            this.cost.cost='';
            const toast = await toastController
            .create({
              color: 'success',
              message: response.data.message,
              duration: 2000
            })
            this.$router.push('/folder/costs-list');
            return toast.present();
          }
        })
      }
    },
  }
</script>
<style scoped>
  #input{
    background-color:#a9bdff48;
    border-radius:5px;
    margin:5px;
    width:90%;
  }
  form{
    padding:10px;
  }
</style>